import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ChatWidget from './ChatWidget';
import './FaqPage.css';
import TestSolution from './images/testsolution.webp';
import ReactDOM from 'react-dom/client';

const FAQs = [
  { question: "What are the council's operating hours?", answer: "Our council operates from Monday to Friday, 9 AM to 5 PM, excluding public holidays. For urgent matters outside these hours, please refer to our emergency contact details on our website." },
  { question: "How do I report a problem?", answer: "To report issues such as potholes, broken streetlights, or graffiti, please use our online reporting system available on our website or call our 24-hour hotline. Provide as much detail as possible, including location and photos if applicable." },
  { question: "Where can I find information about rubbish collection?", answer: "Rubbish and recycling collection schedules, including special waste disposal events, are detailed on our website under the 'Waste Management' section. You can also download the collection calendar for your area." },
  { question: "Can I pay my rates online?", answer: "Yes, council rates can be paid online via our secure payment portal. You can make payments using credit/debit cards or set up direct debit. Visit the 'Rates and Payments' section for guidance and payment options." },
  { question: "How do I apply for a building permit?", answer: "Apply for a building permit online or at the council office. Complete the application form, attach required documents and plans, and pay the applicable fee. Our planning team will review your application and contact you." },
  { question: "What should I do if my bin wasn't collected?", answer: "If your bin wasn't collected, please report it within 24 hours via our website or hotline. Provide your address and bin type (rubbish, recycling, or green waste). We will arrange a collection as soon as possible." },
  { question: "How can I register my pet?", answer: "Pet registration is mandatory and can be completed online or at our council offices. You will need to provide your pet's details and proof of microchipping. Fees vary based on the pet's age and desexing status." },
  { question: "Where can I find information on local events?", answer: "Visit our 'Events and Activities' page for the latest local events, festivals, and workshops. You can search by date, event type, or location, and find information on how to participate or get involved." },
  { question: "How do I contact my local councillor?", answer: "Contact details for all councillors, including email, phone number, and postal address, are available on our 'Councillor Profiles' page. You can reach out to them for constituency matters or council-related enquiries." },
  { question: "Can I lodge a planning objection online?", answer: "Lodge your planning objections online through our 'Planning and Development' portal. Include the planning application reference, your concerns, and any supporting documents. You can also submit objections in person at our office." },
  { question: "Where is the nearest recycling center?", answer: "Find your nearest recycling center locations, operating hours, and accepted materials on our 'Waste Management' page. Remember to check guidelines for hazardous or special waste." },
  { question: "How do I report a street light issue?", answer: "Report street light issues, such as outages or damage, through our 'Report It' online system. Include the light's location and a description of the issue. We coordinate with utility providers for prompt resolution." },
  { question: "What facilities are available at local parks?", answer: "Our 'Parks and Recreation' section provides details on park facilities, including playgrounds, sports fields, picnic areas, and walking trails. Accessibility information and park maps are also available." },
  { question: "How do I enroll in council-run workshops?", answer: "Enroll in our workshops through the 'Events and Workshops' page. Find upcoming workshops, eligibility criteria, and registration details. Workshops cover various topics like community development, health, and environment." },
  { question: "Are there any job vacancies at the council?", answer: "Explore current job openings in various departments on our 'Careers' page. You can view job descriptions, requirements, and application procedures. Subscribe to our newsletter for updates on new vacancies." },
  { question: "How do I get a parking permit?", answer: "Apply for parking permits online or at our customer service centers. Choose from residential, visitor, or business permits based on your needs. You'll need to provide proof of residency or business location." },
  { question: "What should I do about a noisy neighbour?", answer: "For noise complaints, such as loud music or construction, use our online complaint form or call our hotline. Provide details of the noise issue and your location. Our team will investigate and take appropriate action." },
  { question: "How can I apply for a community grant?", answer: "Community grant applications are available on our 'Grants and Funding' page. Find guidelines, eligibility criteria, and application deadlines. Assistance is available for community projects, events, and initiatives." },
  { question: "Where can I find zoning information?", answer: "Access zoning maps and information in the 'Planning and Building' section. Zoning regulations affect land use, building types, and development. Contact our planning department for specific enquiries." },
  { question: "Can I book a council venue for an event?", answer: "Book council venues for events, meetings, or functions online. Visit our 'Facilities Booking' page to view available venues, amenities, booking guidelines, and fees. Some venues may require a deposit or insurance." },
  { question: "How can I report illegal dumping?", answer: "Report illegal dumping through our 'Report It' online service or by calling our hotline. Please provide the location, type of waste, and any other relevant details for our rapid response team." },
  { question: "What is the process for tree removal on my property?", answer: "For tree removal, submit an application through our 'Tree Management' section. Include the tree location, reason for removal, and any supporting documents. A council inspection may be required." },
  { question: "How do I report a lost or found animal?", answer: "Report lost or found animals via our 'Animal Services' page. Provide a description of the animal, location found or lost, and your contact details. Check our online registry for reported lost pets." },
  { question: "Where can I get information about council tax?", answer: "Information on council tax, including rates, payment options, and concessions, is available under our 'Rates and Payments' section. Contact our rates department for specific queries or assistance." },
  { question: "How do I apply for a disability parking permit?", answer: "Apply for a disability parking permit online or at our customer service centers. Provide proof of eligibility and a medical certificate. Permits are issued to individuals, not vehicles." },
  { question: "What are the guidelines for building a fence?", answer: "Building fence guidelines, including height, materials, and boundary issues, are available in our 'Planning and Building' section. For disputes with neighbours, consider our mediation services." },
  { question: "How do I report problems with roads or footpaths?", answer: "Report issues with roads or footpaths, like potholes or cracks, through our online 'Report It' service or hotline. Provide the exact location and a description for prompt maintenance." },
  { question: "Where can I find information on local schools?", answer: "Local school information, including locations, contact details, and enrolment zones, can be found under our 'Education and Schools' section. We provide links to each school's website for detailed information." },
  { question: "Can I request additional rubbish bins?", answer: "Request additional rubbish, recycling, or green waste bins through our 'Waste Management' services. Fees and forms are available online. Note the collection schedule may change with additional bins." },
  { question: "How do I report graffiti?", answer: "Report graffiti on public or private property through our online 'Report It' service. Include the location and a photo if possible. We offer graffiti removal assistance for private properties." },
  { question: "How do I access public records?", answer: "Access public records by visiting our 'Public Records' section. You can request documents related to council meetings, planning decisions, and local policies. Some records may require a formal request under the Freedom of Information Act." },
  { question: "Can I report water leaks or sewerage issues?", answer: "Report water leaks or sewerage issues through our 'Utilities and Infrastructure' reporting system online or by calling our dedicated utilities hotline. Provide the exact location and a description of the issue." },
  { question: "How do I get a copy of my property's floor plan?", answer: "Request a copy of your property's floor plan online through our 'Property and Development' services. You will need to provide proof of ownership and pay a nominal fee for the documentation." },
  { question: "What are the rules for hosting a public event?", answer: "For hosting a public event, refer to our 'Events and Permits' section. You'll need to apply for a permit, adhere to safety guidelines, and possibly arrange for public liability insurance." },
  { question: "How can I volunteer with the council?", answer: "Volunteer opportunities with the council are listed under our 'Community and Living' section. Choose from various roles like community events, library assistance, or environmental conservation." },
  { question: "Where can I find information on public transport?", answer: "Public transport information, including bus routes, timetables, and fare details, can be found under our 'Transport and Infrastructure' section. We provide links to local transport providers for up-to-date information." },
  { question: "How do I apply for a business license?", answer: "Apply for a business license through our 'Business and Economy' section. Fill out the relevant forms and submit them with any required documentation. Our business support team can assist with enquiries." },
  { question: "What should I do if I find a syringe or needle?", answer: "If you find a syringe or needle, do not touch it. Report it immediately through our 'Health and Safety' online form or hotline. Our trained staff will safely dispose of it." },
  { question: "How can I reserve a sports field or facility?", answer: "Reserve sports fields or facilities through our 'Recreation and Sports' booking system. Check availability, fill out a booking form, and adhere to the terms and conditions, including any fees or deposits." }

];

const FAQPage = () => {
  const [searchTerm, setSearchTerm] = useState(''); // Enable search functionality
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredFAQs = FAQs.filter(faq =>
    faq.question.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const currentItems = filteredFAQs.slice(indexOfFirstItem, indexOfLastItem);
  const totalItems = filteredFAQs.length;

  useEffect(() => {
    window.embeddedChatbotConfig = {
      chatbotId: "c1f5f96c-3374-4b54-8d73-ffb6660e4d12",
      account: 'AISSIE'
    };

    // Optional: Dynamically load chat widget script if necessary
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = () => (
    <nav className="pagination" aria-label="FAQ Pagination">
      <button
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={currentPage === 1}
        aria-label="Previous Page"
      >
        Previous
      </button>
      {pageNumbers.map(number => (
        <button
          key={number}
          className={currentPage === number ? 'active' : ''}
          onClick={() => setCurrentPage(number)}
          aria-label={`Page ${number}`}
        >
          {number}
        </button>
      ))}
      <button
        onClick={() => setCurrentPage(prev => Math.min(prev + 1, pageNumbers.length))}
        disabled={currentPage === pageNumbers.length}
        aria-label="Next Page"
      >
        Next
      </button>
    </nav>
  );

  // Generate JSON-LD structured data for FAQs
  const generateFAQSchema = () => {
    const faqStructuredData = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": FAQs.map(faq => ({
        "@type": "Question",
        "name": faq.question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": faq.answer
        }
      }))
    };
    return JSON.stringify(faqStructuredData);
  };

  // Generate breadcrumb structured data
  const generateBreadcrumbSchema = () => {
    const breadcrumbSchema = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'Home',
          item: 'https://www.aissie.com.au/',
        },
        {
          '@type': 'ListItem',
          position: 2,
          name: 'FAQs',
          item: 'https://www.aissie.com.au/demo',
        },
      ],
    };
    return JSON.stringify(breadcrumbSchema);
  };

  return (
    <div className="faq-container">
      <Helmet>
        <title>Council AI Assistant Demonstration - AIssie</title>
        <meta
          name="description"
          content="Find answers to the most common questions about AIssie's services, including operating hours, reporting issues, payment options, and more."
        />
        <link rel="canonical" href="https://www.aissie.com.au/demo" />
        
        {/* Structured Data */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: generateFAQSchema() }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: generateBreadcrumbSchema() }}
        />
      </Helmet>

      <header className="first-screen-faq">
        <div className="faq-text-block">
          <h1>Frequently Asked Questions</h1>
          <p className="hero_subhead">
            Explore our comprehensive collection of questions and answers. Our AIssie AI assistant is here to help you with any council or business-related enquiries. Feel free to interact and find the information you need!
          </p>
          <p>
            You may also test our demo on{' '}
            <a href="https://www.facebook.com/aissie.com.au/" target="_blank" rel="noopener noreferrer">
              Facebook <i className="fab fa-facebook-square" style={{ fontSize: '1.5rem' }}></i>
            </a>{' '}
            or{' '}
            <a href="https://www.instagram.com/aissie.com.au" target="_blank" rel="noopener noreferrer">
              Instagram <i className="fab fa-instagram-square" style={{ fontSize: '1.5rem' }}></i>
            </a>
          </p>
        </div>

        <div className="image-placeholder">
          <img src={TestSolution} alt="AI Assistant demonstrating FAQ functionality" className="hero-image-faq" />
        </div>
      </header>

      <main className="second-screen-faq">
        <h2>Sample Q&As</h2>

        <section className="faq-list">
          {currentItems.length > 0 ? (
            currentItems.map((faq, index) => (
              <div key={index} className="faq-item" tabIndex="0" aria-label={`Question: ${faq.question}`}>
                <h3>{faq.question}</h3>
                <p>{faq.answer}</p>
              </div>
            ))
          ) : (
            <p>No FAQs match your search.</p>
          )}
        </section>

        {renderPageNumbers()}

        <div className="total-count">
          <p>Total Questions/Answers: {totalItems}</p>
        </div>
      </main>

      <footer>
        <div id="aissie-chat"></div>
        <ChatWidget />
      </footer>
    </div>
  );
};

export default FAQPage;
