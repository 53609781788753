import React, { useEffect, useState, useRef } from 'react';
import './ChatWidget.css'; // Importing CSS
import DOMPurify from 'dompurify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faComments, faSyncAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button, TextField, Box } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircularProgress from '@mui/material/CircularProgress';
import ReactMarkdown from 'react-markdown';
import rehypeSanitize from 'rehype-sanitize';

const ChatMessage = ({ msg, style, botIconUrl }) => {
    return (
        <div className={`message ${msg.role}`} style={{
            color: msg.role === 'assistant' ? style.botFontColor : style.userFontColor,
            backgroundColor: msg.role === 'assistant' ? style.botBackgroundColor : style.userBackgroundColor
        }}>
            {msg.role === 'assistant' && botIconUrl && (
                <img src={botIconUrl} alt="Bot" className="bot-icon" />
            )}
            <ReactMarkdown
                children={msg.content}
                rehypePlugins={[rehypeSanitize]}
                components={{
                    a: ({ node, ...props }) => (
                        <a {...props} target="_blank" rel="noopener noreferrer" style={{ color: style.linkColor || '#1a0dab' }
                        }>
                            {props.children}
                        </a>
                    ),
                    p: ({ node, ...props }) => (
                        <p style={{ color: 'inherit' }} {...props}>
                            {props.children}
                        </p>
                    ),
                    // Add more custom components if needed
                }}
            />
        </div>
    );
};

const ChatWidget = () => {
    const [config, setConfig] = useState(null);
    const [style, setStyle] = useState(null);
    const [lead, setLead] = useState(null);
    const [loading, setLoading] = React.useState(false);
    const [chatHistory, setChatHistory] = useState([]); // Initialise with an empty array
    const [suggestedMessages, setSuggestedMessages] = useState([]);
    const [assistantMessageCount, setAssistantMessageCount] = useState(0)
    const [showLeadCapture, setShowLeadCapture] = useState(false);
    const isMobile = window.innerWidth < 768; // Common breakpoint for mobile devices
    const [isChatVisible, setIsChatVisible] = useState(!isMobile);
    const [isSending, setIsSending] = useState(false);
    const messagesEndRef = useRef(null);
    const inputRef = useRef(null);
    const [isAssistantTyping, setIsAssistantTyping] = useState(false);
    const [botIconUrl, setBotIconUrl] = useState('');

    // State to track lead details and errors
    const [leadDetails, setLeadDetails] = useState({});
    const [leadErrors, setLeadErrors] = useState({});

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        const checkConfig = async () => {
            const interval = setInterval(() => {
                if (window.embeddedChatbotConfig?.chatbotId) {
                    clearInterval(interval);
                    fetchConfig();
                }
            }, 100); // Check every 100ms

            const fetchConfig = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chatbots/${window.embeddedChatbotConfig.chatbotId}/style`, {
                        method: 'GET',
                        credentials: 'include',
                    });

                    const data = await response.json();
                    if (data.success) {
                        setConfig(data.config);
                        setStyle(data.config.style);
                        setLead(data.config.lead_configuration);
                        let parsedSuggestedMessages = [];

                        if (typeof data.config.suggested_messages === 'string') {
                            try {
                                const parsedData = JSON.parse(data.config.suggested_messages);
                                parsedSuggestedMessages = parsedData.suggested_messages || [];
                            } catch (error) {
                                console.error('Error parsing suggested_messages:', error);
                            }
                        } else {
                            parsedSuggestedMessages = data.config.suggested_messages || [];
                        }
                        setSuggestedMessages(parsedSuggestedMessages);

                        if (data.config.style.chatbot_icon) {
                            setBotIconUrl(`https://aissieragfiles.blob.core.windows.net/chatbot-icons/${data.config.style.chatbot_icon}`);
                        }
                    } else {
                        console.error('Failed to fetch config:', data.message);
                    }
                } catch (error) {
                    console.error('Error fetching config:', error);
                }
            };
        };

        checkConfig();
    }, []);

    // Initialize leadDetails when config is loaded
    useEffect(() => {
        if (config?.lead_configuration?.fields) {
            const initialLeadDetails = {};
            config.lead_configuration.fields.forEach(field => {
                const fieldName = field.name.toLowerCase().replace(' ', '_');
                initialLeadDetails[fieldName] = '';
            });
            setLeadDetails(initialLeadDetails);
        }
    }, [config]);

    useEffect(() => {
        if (config) {
            fetch(`${process.env.REACT_APP_API_URL}/api/chat_history?chatbot_id=${window.embeddedChatbotConfig.chatbotId}`, {
                method: 'GET',
                credentials: 'include',
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        const welcomeMessage = {
                            role: 'assistant',
                            content: config.initial_messages
                        };

                        // Ensure data.data is an array
                        const chatHistoryData = Array.isArray(data.data) ? data.data : [];

                        // Combine the welcome message with the existing chat history
                        setChatHistory([welcomeMessage, ...chatHistoryData]);
                        scrollToBottom(); // Scroll to bottom after loading chat history
                    }
                })
                .catch(error => console.error('Error:', error));
        }
    }, [config]);

    useEffect(() => {
        const chatHeader = document.getElementById('chat-header');
        if (chatHeader && chatHeader.textContent.trim() === "Click Here for Demo") {
            chatHeader.style.animation = "textColorChange 4s infinite";
        } else if (chatHeader) {
            chatHeader.style.animation = 'none'; // Reset the animation if the condition is not met
        }
    }, [isChatVisible]); // Dependency array, re-run effect when isChatVisible changes

    useEffect(scrollToBottom, [chatHistory]);

    const toggleChat = () => {
        setIsChatVisible(!isChatVisible);
    };
    const handleRefresh = () => {
        // Call the Flask API to delete the cookie
        fetch(`${process.env.REACT_APP_API_URL}/api/clear_session`, {
            method: 'POST',
            credentials: 'include',
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    // Clear the conversation data
                    setChatHistory([]);

                    // Display the welcome message
                    const welcomeMessage = {
                        role: 'assistant',
                        content: config.initial_messages
                    };
                    setChatHistory([welcomeMessage]);

                    // Display all the original suggested messages
                    let parsedSuggestedMessages = [];

                    if (typeof config.suggested_messages === 'string') {
                        try {
                            const parsedData = JSON.parse(config.suggested_messages);
                            parsedSuggestedMessages = parsedData || [];
                        } catch (error) {
                            console.error('Error parsing suggested_messages:', error);
                        }
                    } else {
                        parsedSuggestedMessages = config.suggested_messages || [];
                    }
                    setSuggestedMessages(parsedSuggestedMessages);

                } else {
                    console.error('Failed to clear session:', data.message);
                }
            })
            .catch(error => console.error('Error:', error));
    };

    useEffect(() => {
        if (isChatVisible) {
            scrollToBottom();
        }
    }, [isChatVisible, chatHistory]);

    const addMessageToChat = (role, content) => {
        const newMessage = { role, content };
        setChatHistory(prevHistory => [...prevHistory, newMessage]);
    };

    const sendMessage = () => {
        const messageField = inputRef.current;
        const message = messageField.value.trim();
        if (!message) return;

        setIsSending(true);

        // Add user's message to chat history
        addMessageToChat('user', message);
        messageField.value = '';
        scrollToBottom();
        setIsAssistantTyping(true); // Set typing status to true

        fetch(`${process.env.REACT_APP_API_URL}/api/ask`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ question: message, chatbot_id: window.embeddedChatbotConfig.chatbotId }),
        })
            .then(response => response.json())
            .then(data => {
                setIsAssistantTyping(false); // Set typing status to false after receiving response
                // Add bot's response to chat history
                if (data.success) {
                    addMessageToChat('assistant', data.response);
                    setAssistantMessageCount(assistantMessageCount + 1)
                } else {
                    if (config?.fallback_message) {
                        addMessageToChat('assistant', config.fallback_message);
                    }
                    // setAssistantMessageCount(assistantMessageCount + 1)

                }


            })
            .catch(error => {
                console.error('Error:', error);
                setIsAssistantTyping(false); // Set typing status to false after receiving response
                if (config?.fallback_message) {
                    addMessageToChat('assistant', config.fallback_message);
                }
            })
            .finally(() => {
                setIsSending(false);
            });
    };

    const handleSuggestedMessageClick = (question, index) => {
        inputRef.current.value = question;

        // Update chat history immediately to add the user's message
        sendMessage();

        // Update suggested messages separately
        const updatedSuggestedMessages = [...suggestedMessages];
        updatedSuggestedMessages.splice(index, 1);
        setSuggestedMessages(updatedSuggestedMessages);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey && !isSending) {
            event.preventDefault();  // Prevents adding a newline character
            sendMessage();
        }
    };

    useEffect(() => {

        if (config?.lead_configuration?.active && assistantMessageCount === config.lead_configuration.num_messages) {
            setTimeout(() => {
                setShowLeadCapture(true);
            }, 2000); // 2000 milliseconds = 2 seconds
        }
    }, [assistantMessageCount, config]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLeadDetails(prevDetails => ({ ...prevDetails, [name]: value }));
        // Clear error when user starts typing
        setLeadErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    };

    // Validation function for lead fields
    const validateLead = () => {
        const errors = {};
        if (!config || !config.lead_configuration || !config.lead_configuration.fields) {
            return true; // No validation needed
        }

        config.lead_configuration.fields.forEach(field => {
            const fieldName = field.name.toLowerCase().replace(' ', '_');
            const value = leadDetails[fieldName] ? leadDetails[fieldName].trim() : '';

            if (field.mandatory) {
                if (!value) {
                    errors[fieldName] = `${field.name} is required`;
                } else {
                    // Additional validation based on field type
                    if (field.name.toLowerCase() === 'email') {
                        // Basic email regex
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!emailRegex.test(value)) {
                            errors[fieldName] = 'Invalid email address';
                        }
                    }
                    if (field.name.toLowerCase() === 'phone') {
                        // Basic phone regex (digits, maybe +, -, etc.)
                        const phoneRegex = /^[0-9+\-() ]+$/;
                        if (!phoneRegex.test(value)) {
                            errors[fieldName] = 'Invalid phone number';
                        }
                    }
                }
            } else {
                // For non-mandatory fields, if filled, validate
                if (value) {
                    if (field.name.toLowerCase() === 'email') {
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!emailRegex.test(value)) {
                            errors[fieldName] = 'Invalid email address';
                        }
                    }
                    if (field.name.toLowerCase() === 'phone') {
                        const phoneRegex = /^[0-9+\-() ]+$/;
                        if (!phoneRegex.test(value)) {
                            errors[fieldName] = 'Invalid phone number';
                        }
                    }
                }
            }
        });

        setLeadErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleLeadSubmit = () => {
        // Validate lead fields before submitting
        if (!validateLead()) {
            return; // Prevent submission if validation fails
        }

        setLoading(true);
        const payload = {
            chatbot_id: window.embeddedChatbotConfig.chatbotId,
            name: leadDetails.name || null,
            email: leadDetails.email || null,
            phone: leadDetails.phone || null
        };

        const customFields = {};

        Object.keys(leadDetails).forEach(key => {
            if (!['name', 'email', 'phone'].includes(key)) {
                customFields[key] = leadDetails[key];
            }
        });

        // Add custom fields to the payload only if there are any
        if (Object.keys(customFields).length > 0) {
            payload.custom = customFields;
        }

        fetch(`${process.env.REACT_APP_API_URL}/api/leads`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                setLoading(false);
                if (data.success) {
                    setShowLeadCapture(false);  // Close the popup on success
                    // Optionally show a success message or handle any post-submit actions here
                } else {
                    // Handle submission error
                    console.error('Failed to submit lead:', data.message);
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('Error submitting lead:', error);
            });
    };



    if (!config || !style) {
        return null; // Render a loading indicator or spinner here if needed
    }

    // Add a console log to check the style object
    return (
        <div id="chat-widget" style={{ backgroundColor: style.backgroundColor }} >
            {isChatVisible ? (
                <>
                    <div id="chat-header" style={{ backgroundColor: style.ThemeBackgroundColor, color: style.ThemeFontColor }}>
                        <div className="header-content">
                            <span className="minimise-text">{style.displayName}</span>
                            <FontAwesomeIcon icon={faMinus} className="minimise-icon" onClick={toggleChat} title='Minimise Chat' />
                            <FontAwesomeIcon icon={faSyncAlt} className="refresh-icon" onClick={handleRefresh} title='Refresh Chat' />
                        </div>
                    </div>
                    <div id="chat-body" style={{ backgroundColor: style.backgroundColor, overflowX: "hidden" }}>
                        {chatHistory.map((msg, index) => (
                            <ChatMessage key={index} msg={msg} style={style} botIconUrl={botIconUrl} />
                        ))}
                        {isAssistantTyping && (
                            <div className="message assistant" >
                                {botIconUrl && <img src={botIconUrl} alt="Bot" className="bot-icon" />}
                                <div className="typing-animation">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        )}

                        <div ref={messagesEndRef} />
                        {suggestedMessages.length > 0 && (
                            <div id="suggested-questions">
                                <div className="suggestions-header">
                                    <FontAwesomeIcon icon={faTimes} className="close-icon" onClick={() => setSuggestedMessages([])} />
                                </div>
                                <div className="suggestions-container">
                                    {suggestedMessages.map((question, index) => (
                                        <Button
                                            variant='outlined'
                                            key={index}
                                            onClick={() => handleSuggestedMessageClick(question, index)}
                                            sx={{
                                                fontFamily: 'inherit',
                                                backgroundColor: style.ThemeBackgroundColor, color: style.ThemeFontColor, textTransform: 'none',
                                                '&:hover': {
                                                    opacity: 0.8, // Reduce opacity on hover
                                                    backgroundColor: style.ThemeBackgroundColor
                                                }
                                            }}
                                            endIcon={<ChevronRightIcon />}
                                        >
                                            {question}
                                        </Button>
                                    ))}
                                </div>
                            </div>
                        )}

                    </div>

                    <div id="chat-footer">
                        <TextField
                            id="chat-input"
                            inputRef={inputRef}
                            autoFocus={false}
                            maxRows={4}
                            placeholder={style.messagePlaceholder}
                            onKeyDown={handleKeyDown}
                            variant="outlined"
                            fullWidth
                            multiline
                            InputProps={{
                                sx: {
                                    padding: '0.5rem', // Remove padding inside InputBase and OutlinedInput

                                },
                            }}
                            sx={{
                                backgroundColor: 'white',
                                lineHeight: '100%',
                                height: '100%',
                                padding: 'unset',
                                fontSize: '40px !important',
                                marginRight: '0.5rem',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'unset', // Default border colour
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'unset', // Border colour on hover
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: style.ThemeBackgroundColor, // Border colour when focused
                                    }
                                },
                            }}
                        />

                        <button
                            id="send-button"
                            disabled={isSending}
                            onClick={sendMessage}
                            style={{ backgroundColor: style.ThemeBackgroundColor, color: style.ThemeFontColor }}
                        >
                            {style.sendLabel}
                        </button>
                    </div>
                    <div id="footer">
                        Powered by <a href="https://www.aissie.com.au" target="_blank" rel="noopener noreferrer">AIssie</a>
                    </div>
                    {showLeadCapture && (
                        <div className="lead-capture-popup">
                            <div className="popup-content">
                                <p><b>{config.lead_configuration.message}</b></p>

                                {config.lead_configuration.fields.map((field) => {
                                    const fieldName = field.name.toLowerCase().replace(' ', '_');
                                    return (
                                        <TextField
                                            key={fieldName} // Unique key for each field
                                            type={
                                                field.name.toLowerCase() === 'email' ? 'email' :
                                                field.name.toLowerCase() === 'phone' ? 'tel' :
                                                'text'
                                            }
                                            name={fieldName} // Use the formatted field name
                                            label={field.name}
                                            placeholder={field.name}
                                            value={leadDetails[fieldName]}
                                            onChange={handleInputChange}
                                            required={field.mandatory}
                                            error={!!leadErrors[fieldName]}
                                            helperText={leadErrors[fieldName]}
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    );
                                })}

                                <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                                    <Button
                                        onClick={handleLeadSubmit}
                                        disabled={loading}
                                        variant='contained'
                                        sx={{
                                            backgroundColor: style.ThemeBackgroundColor, color: style.ThemeFontColor, textTransform: 'none',
                                            '&:hover': {
                                                opacity: 0.8, // Reduce opacity on hover
                                                backgroundColor: style.ThemeBackgroundColor
                                            }
                                        }}
                                    >
                                        {style.sendLabel}
                                    </Button>
                                    <Button
                                        variant='contained'
                                        color="error"
                                        disabled={loading || (lead?.required)}
                                        sx={{
                                            textTransform: 'none', marginLeft: '0.5rem',
                                            '&:hover': {
                                                opacity: 0.8, // Reduce opacity on hover
                                            }
                                        }}
                                        onClick={() => setShowLeadCapture(false)}
                                    >
                                        Close
                                    </Button>
                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: style.ThemeFontColor,
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                            </div>
                        </div>
                    )}

                </>
            ) : (
                botIconUrl ? (
                    <div className="circle-icon" onClick={toggleChat}>
                        <img src={botIconUrl} alt="Bot" className="bot-icon" onClick={toggleChat} />
                        <div className="badge">1</div> {/* Add this badge element */}

                    </div>
                ) : (
                    <div className="circle-icon" style={{ backgroundColor: style.ThemeBackgroundColor }} onClick={toggleChat}>
                        <FontAwesomeIcon icon={faComments} />
                        <div className="badge">1</div> {/* Add this badge element */}
                    </div>
                )
            )}
        </div>
    );
};

export default ChatWidget;
