import React from 'react';
import './HomePage.css';
import ChatImage from './images/mobilef-min3.webp';
//import Confused from './images/confused.webp';
import ComplexQuery from './images/complex_query.webp';
import Support from './images/247support.webp';
import Flags from './images/flags.webp';
import Price from './images/price.webp';
import Costs from './images/costs.webp';
import Improvement from './images/improvement.webp';
import ExpandableCard from './card';
import User from './images/confused.webp';
import BusinessUser from './images/business_representative4.webp';
import Easy_Maintenance from './images/easy_maintenance.webp';
import Implementation from './images/implementation.webp';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import MiniCard from './MiniCard';

const HomePage = () => {
  const navigate = useNavigate()
  const currentPage = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);
  
  const handleTryNowClick = () => {
    // Push the event to dataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'tryNowClick',
      'eventCategory': 'Button',
      'eventAction': 'Click',
      'eventLabel': 'Try Now'
    });
    navigate('/pricing')
  };
  return (
    <main className="homepage">
      <Helmet>
        <title>AIssie - Real-Time AI Assistant</title>
        <meta
          name="description"
          content="AIssie AI assistant solution provides real-time responses to customer enquiries, reducing the workload on your team while improving the overall customer experience. Additionally, it can handle bookings, appointments, send reminders, and provide quotes, offering a comprehensive solution that benefits both your business and your customers – truly a win-win!"
        />
        <link rel="canonical" href="https://www.aissie.com.au/" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.aissie.com.au/" />
        <meta property="og:title" content="AIssie - Real-Time AI Assistant" />
        <meta
          property="og:description"
          content="AIssie AI assistant solution provides real-time responses to customer enquiries."
        />
        <meta property="og:image" content="https://www.aissie.com.au/favicon.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://www.aissie.com.au/" />
        <meta name="twitter:title" content="AIssie - Real-Time AI Assistant" />
        <meta
          name="twitter:description"
          content="AIssie AI assistant solution provides real-time responses to customer enquiries."
        />
        <meta name="twitter:image" content="https://www.aissie.com.au/social-preview.png" />

        <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "AIssie",
          "url": "https://www.aissie.com.au",
          "logo": "https://www.aissie.com.au/favicon.png",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+61499712235",
            "contactType": "Customer Support"
          },
          "sameAs": [
            "https://www.linkedin.com/company/aissie"
          ]
        }
      `}</script>
      </Helmet>
      <section className="first-screen">
        <div className="first-text-block">
          <h1>Change Your Relationship With Your Customers</h1>
          <p className="hero_subhead">AIssie delivers real-time responses, streamlining customer enquiries and increasing your team's efficiency. It also manages bookings, reminders, and quotes, delivering a complete solution that benefits both your business and your customers – a true win-win!</p>
          <button type="button" className='button-home' onClick={handleTryNowClick}>
          Start Your Free Trial
          </button>
        </div>
        <div className="image-placeholder">
          <img src={ChatImage} alt="AI Assistant" className="hero-image" />
        </div>
      </section>

      <section className="large-container">
        <div className="problem-statement">
          <div className="second-text-block">
            <h2>Current Challenges in Business Services</h2>
            <p>We recognise the difficulties both end users and business representatives encounter in accessing and managing information effectively.</p>
            <div className='challenges'>
              {/* <div className="image-placeholder">
              <img src={Confused} alt="AI Assistant" className="hero-image" />
            </div>

            {/*<p>Have you, as a user, ever found it challenging to locate the information you need on a Council's website? Perhaps you've navigated through numerous FAQ pages and interacted with outdated chatbots that offer limited assistance, only to abandon your search or resort to calling the council directly. We understand these frustrations. However, we have some good news! AIssie has been created to transform the way users interact with Council websites through the use of the latest AI technology.</p>*/}
              <div className='cards'>
                <ExpandableCard
                  title="User"
                  image={User} // replace with your image source
                  //title="24/7 Instant Support"
                  summary="User Challenges"
                  details="
                -Absence of real-time assistance options for immediate help \n
                -Limited communication options such as web chat or social medias \n
                -Trouble finding the right information on websites \n
                -Poorly organised or incomplete FAQ sections \n
                -Limited availability of information in multiple languages.
          
              
              "
                />
                <ExpandableCard
                  title="Business Representative"
                  image={BusinessUser} // replace with your image source
                  summary="Business Representative Challenges"
                  details="-Ensuring easy user access to information \n
                Training staff to adhere to procedures and accurately respond to enquiries    \n
                Managing and maintaining the knowledge base, often requiring IT support \n
                Analysing user interactions with the knowledge base to drive improvements \n
                Translating content into various languages
              "
                />
              </div>
            </div>
          </div>



        </div>

      </section>

      <section className="solution">
        <h2>Our Solution: AIssie AI assistant</h2>
        <p>Our solution not only provides real-time, multilingual support with an understanding of nuances and accurate information using the latest AI technology, but it also enables seamless booking and appointment scheduling. Additionally, it sends timely reminders and can provide quotes to streamline your processes.</p>
        <button type="button" className='button-home' onClick={handleTryNowClick}>
        Start Your Free Trial
        </button>
      </section>

      <section className="solution">
        <h3>How it Works</h3>
        <div className="solution-items">
          <article className="solution-item">
            <MiniCard
              className="solution-card solution-hover-effect"
              colorClass="brown"
              frontContent={
                <>
                  <div className="cardNumber brown">
                    <span>1</span>
                  </div>
                  <div className="cardHeader">
                    <span>Upload your Knowledge Base!</span>
                  </div>
                  <div className="cardDetails">
                    <p>More Details</p>
                  </div>
                </>
              }
              backContent={
                <p>
                Sign up to create your AI assistant and upload your knowledge base – FAQs, business files, or website. You can also add your services and availability, so AIssie knows when bookings are open. It will then be ready to handle enquiries tailored to your business!
                </p>
              }
            />
          </article>

          {/* Repeat for other solution items */}
          <article className="solution-item">
            <MiniCard
              className="solution-card solution-hover-effect"
              colorClass="yellow"
              frontContent={
                <>
                  <div className="cardNumber yellow">
                    <span>2</span>
                  </div>
                  <div className="cardHeader">
                    <span>Test It!</span>
                  </div>
                  <div className="cardDetails">
                    <p>More Details</p>
                  </div>
                </>
              }
              backContent={
                <p>
                  You'll be able to test the AI assistant. Feel free to test it out by asking all
                  sorts of questions in any language you prefer. We'll be keen to hear how it
                  performs for you!
                </p>
              }
            />
          </article>

          <article className="solution-item">
            <MiniCard
              className="solution-card solution-hover-effect "
              colorClass="green"
              frontContent={
                <>
                  <div className="cardNumber green">
                    <span>3</span>
                  </div>
                  <div className="cardHeader">
                    <span>Deploy!</span>
                  </div>
                  <div className="cardDetails">
                    <p>More Details</p>
                  </div>
                </>
              }
              backContent={
                <p>
                  We make integrating our AI assistant solution a breeze. We'll provide a simple code
                  snippet that you or your IT team can effortlessly add to the necessary pages on
                  your website. Alternatively, we may also integrate with your social media platforms
                  such as Facebook, Instagram, or WhatsApp!
                </p>
              }
            />
          </article>

          <article className="solution-item">
            <MiniCard
              className="solution-card solution-hover-effect "
              colorClass="blue"
              frontContent={
                <>
                  <div className="cardNumber blue">
                    <span>4</span>
                  </div>
                  <div className="cardHeader">
                    <span>Review!</span>
                  </div>
                  <div className="cardDetails">
                    <p>More Details</p>
                  </div>
                </>
              }
              backContent={
                <p>
                  Take a look at how the AIssie assistant handled real customer questions and then
                  update your Knowledge Base based on what you find. This way, the AIssie assistant
                  will get even better at responding next time around!
                </p>
              }
            />
          </article>
          
        </div>

      </section>

      {/* Benefits Section */}
      <section className="benefits">
        <h3>Key Benefits</h3>
        <div className="benefit-items">
          <article className="benefit-item">
            <MiniCard
              className="benefit-card"
              frontContent={
                <>
                  <h4>Easy Implementation</h4>
                  <div className="benefits-image">
                    <img src={Implementation} alt="Easy Implementation" />
                  </div>
                  <div className="cardDetails">
                    <p>More Details</p>
                  </div>
                </>
              }
              backContent={
                <p>
                  Say goodbye to complicated consulting projects with multiple stakeholders, delayed
                  timelines, and exceeded budgets. We'll have you up and running in just one day!
                </p>
              }
            />
          </article>

          {/* Repeat for other benefit items */}
          <article className="benefit-item">
            <MiniCard
              className="benefit-card"
              frontContent={
                <>
                  <h4>Pay As You Go</h4>
                  <div className="benefits-image">
                    <img src={Price} alt="Pay As You Go" />
                  </div>
                  <div className="cardDetails">
                    <p>More Details</p>
                  </div>
                </>
              }
              backContent={
                <p>
                  Select one of our subscription options that match your business expectations and
                  pay as you go. This structure combines the reliability of consistent service with
                  the flexibility of usage-based expenses.
                </p>
              }
            />
          </article>

          <article className="benefit-item">
            <MiniCard
              className="benefit-card"
              frontContent={
                <>
                  <h4>Reduce Customer Service Costs</h4>
                  <div className="benefits-image">
                    <img src={Costs} alt="Reduce Customer Service Costs" />
                  </div>
                  <div className="cardDetails">
                    <p>More Details</p>
                  </div>
                </>
              }
              backContent={
                <p>
                  Using customer service AI assistants can cut costs, as they replace the need for
                  extra staff and offer 24/7 service, leading to efficient resource use and savings
                  in customer service.
                </p>
              }
            />
          </article>

          <article className="benefit-item">
            <MiniCard
              className="benefit-card"
              frontContent={
                <>
                  <h4>Multilingual Communication</h4>
                  <div className="benefits-image">
                    <img src={Flags} alt="Multilingual Communication" />
                  </div>
                  <div className="cardDetails">
                    <p>More Details</p>
                  </div>
                </>
              }
              backContent={
                <p>
                  AIssie AI assistants can converse in multiple languages, breaking down language
                  barriers. This feature is especially beneficial in diverse communities, allowing
                  more customers to access business services in their preferred language.
                </p>
              }
            />
          </article>
        </div>

        {/* Second Row of Benefits */}
        <div className="benefit-items">
          <article className="benefit-item">
            <MiniCard
              className="benefit-card"
              frontContent={
                <>
                  <h4>Easy Maintenance</h4>
                  <div className="benefits-image">
                    <img src={Easy_Maintenance} alt="Easy Maintenance" />
                  </div>
                  <div className="cardDetails">
                    <p>More Details</p>
                  </div>
                </>
              }
              backContent={
                <p>
                  Maintain your Knowledge Base up to date and simply upload it to our Admin Portal –
                  it's that easy! No need for IT support.
                </p>
              }
            />
          </article>

          <article className="benefit-item">
            <MiniCard
              className="benefit-card"
              frontContent={
                <>
                  <h4>Understanding Complex Queries</h4>
                  <div className="benefits-image">
                    <img src={ComplexQuery} alt="Understanding Complex Queries" />
                  </div>
                  <div className="cardDetails">
                    <p>More Details</p>
                  </div>
                </>
              }
              backContent={
                <p>
                  Thanks to the advanced AI technology, AIssie assistants can understand and process
                  complex questions, providing precise and relevant answers. This level of
                  comprehension is a significant step up from standard chatbots, which often struggle
                  with anything beyond simple queries.
                </p>
              }
            />
          </article>

          <article className="benefit-item">
            <MiniCard
              className="benefit-card"
              frontContent={
                <>
                  <h4>24/7 Instant Support</h4>
                  <div className="benefits-image">
                    <img src={Support} alt="24/7 Instant Support" />
                  </div>
                  <div className="cardDetails">
                    <p>More Details</p>
                  </div>
                </>
              }
              backContent={
                <p>
                  AIssie AI assistants are available around the clock, ensuring that customers can
                  get immediate responses to their enquiries any time of the day. Unlike traditional
                  methods, there's no need to wait for office hours or navigate through outdated FAQ
                  pages.
                </p>
              }
            />
          </article>

          <article className="benefit-item">
            <MiniCard
              className="benefit-card"
              frontContent={
                <>
                  <h4>Continuous Improvement</h4>
                  <div className="benefits-image">
                    <img src={Improvement} alt="Continuous Improvement" />
                  </div>
                  <div className="cardDetails">
                    <p>More Details</p>
                  </div>
                </>
              }
              backContent={
                <p>
                  By regularly reviewing interactions with the AI assistant and keeping the knowledge
                  base current, the AIssie AI assistant will consistently enhance its performance,
                  ensuring it delivers precise and relevant responses to your customers.
                </p>
              }
            />
          </article>
        </div>
        <button type="button" className='button-home' onClick={handleTryNowClick}>
          Start Your Free Trial
          </button>
      </section>
      <div>
      </div>
    </main>
  );
};

export default HomePage;
