import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ChatWidget from './ChatWidget';
import './DemoImmigration.css'; // Renamed CSS file for clarity
import TestSolution from './images/testsolution.webp';

const ImmigrationAIPage = () => {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    window.embeddedChatbotConfig = {
      chatbotId: 'ee064819-97d3-41e6-b4df-ecfec363c928',
      account: 'Immigration',
    };
    // Optional: Dynamically load chat widget script if necessary
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  // Generate structured data for the AI Assistant page
  const generateAIPageSchema = () => {
    const aiPageSchema = {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      name: 'Immigration AI Assistant',
      description:
        'Explore Immigration AI Assistant, designed to provide information about the australian visas and skills assessment.',
      url: 'https://www.aissie.com.au/immigration-demo',
      image: 'https://www.aissie.com.au/images/testsolution.webp', // Update with the correct image URL
    };
    return JSON.stringify(aiPageSchema);
  };

  // Generate breadcrumb structured data
  const generateBreadcrumbSchema = () => {
    const breadcrumbSchema = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      'itemListElement': [
        {
          '@type': 'ListItem',
          'position': 1,
          'name': 'Home',
          'item': 'https://www.aissie.com.au/',
        },
        {
          '@type': 'ListItem',
          'position': 2,
          'name': 'Immigration AI Assistant',
          'item': 'https://www.aissie.com.au/immigration-demo',
        },
      ],
    };
    return JSON.stringify(breadcrumbSchema);
  };

  return (
    <div className="immigration-ai-container">
      <Helmet>
        <title>Immigration AI Assistant</title>
        <meta
          name="description"
          content="Explore Immigration AI Assistant, designed to provide information about the australian visas and skills assessment."
        />
        <link rel="canonical" href="https://www.aissie.com.au/immigration-demo" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: generateAIPageSchema() }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: generateBreadcrumbSchema() }}
        />
      </Helmet>

      <header className="first-screen-immigration-ai">
        <div className="ai-text-block">
          <h1>Immigration AI Assistant</h1>
          <p className="hero_subhead">
            Welcome to the Immigration AI Assistant Demo! This assistant provides
             information about the main Australian visas and the ACS / Vetasses skills assessment.

             Note: This assistant may provide inaccurate answers. Please ensure you validate the information through official websites or consult an immigration agent.
</p>
        </div>

        <div className="image-placeholder">
          <img
            src={TestSolution}
            alt="AI Assistant demonstrating FAQ functionality"
            className="hero-image-faq"
          />
        </div>
      </header>

      <footer>
        <div id="immigration-chat"></div>
        <ChatWidget />
      </footer>
    </div>
  );
};

export default ImmigrationAIPage;
