import React from 'react';
import './Privacy.css'; // Assuming a CSS file for styling

const Privacy = () => {
  return (
    <div className='privacy'>
      <h2>Privacy Policy</h2>
      <p><strong>Effective Date:</strong> 03/04/2024</p>
      <p><strong>Last Reviewed:</strong> 15/10/2024</p>

      <p>
        AIssie ("we", "us", "our") values and respects the privacy of the people we interact with. We are committed to protecting your privacy and complying with the Privacy Act 1988 (Cth) (Privacy Act) and other applicable privacy laws and regulations.
      </p>
      <p>
        This Privacy Policy ("Policy") describes how we collect, hold, use, and disclose your personal information, and how we maintain the quality and security of your personal information.
      </p>

      <h3>1. Scope and Applicability</h3>
      <p>
        This Policy applies to all users of our platform, including users located in Australia and internationally. By using our services, you agree to the collection and use of your information in accordance with this Policy.
      </p>

      <h3>2. What is Personal Information?</h3>
      <p>
        “Personal information” means any information or opinion, whether true or not, and whether recorded in a material form or not, about an identified individual or an individual who is reasonably identifiable. This includes information that directly or indirectly identifies you, such as your name, email address, and other identifiers.
      </p>
      <p>
        We do not knowingly collect sensitive personal information, such as financial data, health information, or other special categories of data. If you choose to provide such information, we will handle it with the highest level of care and security.
      </p>

      <h3>3. What Personal Information Do We Collect?</h3>
      <p>
        The personal information we collect about you depends on the nature of your interactions with us or what you choose to share. This may include:
      </p>
      <ul>
        <li>Name</li>
        <li>Company</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>Job title</li>
        <li>IP address</li>
        <li>Device information (e.g., browser type, operating system)</li>
        <li>Usage data (e.g., interaction with chatbots, features used)</li>
      </ul>
      <p>
        We may also collect information through automated technologies such as cookies and web beacons (see "Online Activity" below for more details).
      </p>
      <p>
        You do not have to provide us with your personal information. However, if you choose not to provide certain information, it may limit your ability to use some of our services.
      </p>

      <h3>4. How Do We Collect Your Personal Information?</h3>
      <p>
        We collect your personal information in the following ways:
      </p>
      <ul>
        <li>When you interact with us over the phone.</li>
        <li>When you interact with us in person.</li>
        <li>When you interact with us online through our website or platform.</li>
        <li>When you participate in surveys or questionnaires.</li>
        <li>When you attend an AIssie event.</li>
        <li>When you subscribe to our mailing list.</li>
        <li>When you apply for a position with us as an employee, contractor, or volunteer.</li>
        <li>Automatically through cookies and similar technologies when you use our website or services.</li>
      </ul>
      <p>
        We do not collect your personal information from third parties or through publicly available sources without your consent.
      </p>

      <h3>5. Legal Basis for Processing Your Personal Information</h3>
      <p>
        We process your personal information based on the following legal grounds:
      </p>
      <ul>
        <li><strong>Consent:</strong> When you have given clear consent for us to process your personal information for a specific purpose.</li>
        <li><strong>Contractual Necessity:</strong> When processing is necessary for a contract we have with you, such as providing our services.</li>
        <li><strong>Legal Obligation:</strong> When processing is necessary for compliance with a legal obligation.</li>
        <li><strong>Legitimate Interests:</strong> When processing is necessary for our legitimate interests, provided your interests and fundamental rights do not override those interests.</li>
      </ul>

      <h3>6. How Do We Use Your Personal Information?</h3>
      <p>
        We use your personal information for the following purposes:
      </p>
      <ul>
        <li>To deliver a personalized experience and tailored service offerings.</li>
        <li>To improve the quality and functionality of our services.</li>
        <li>For internal administrative purposes, such as managing user accounts and billing.</li>
        <li>For marketing and research purposes, including sending promotional materials and conducting market analysis.</li>
        <li>To communicate with you about updates, changes, or other information related to our services.</li>
        <li>To enforce our terms and conditions and protect our rights and property.</li>
      </ul>
      <p>
        Additionally, if you use our chatbot creation tools, we may analyze interaction data to enhance the performance and capabilities of the chatbots.
      </p>

      <h3>7. Disclosure of Personal Information to Third Parties</h3>
      <p>
        We may disclose your personal information to third parties in the following circumstances:
      </p>
      <ul>
        <li><strong>Service Providers:</strong> We engage third-party service providers to perform functions on our behalf, such as cloud storage, web hosting, email services, and database management. These include:
          <ul>
            <li>OpenAI (AI Service)</li>
            <li>Microsoft Azure (Web Hosting service and Database)</li>
            <li>Google (Email)</li>
            <li>Redis (Database)</li>
            <li>VentraIP (Web Hosting service)</li>
          </ul>
        </li>
        <li><strong>With Your Consent:</strong> We may share your personal information with third parties if you have explicitly consented to such sharing.</li>
        <li><strong>Legal Requirements:</strong> We may disclose your information to comply with legal obligations, such as responding to subpoenas, court orders, or other legal processes.</li>
        <li><strong>Protection of Rights:</strong> We may disclose your information to protect our rights, property, or safety, or the rights, property, or safety of others.</li>
        <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred as part of the transaction.</li>
      </ul>

      <h3>8. Transfer of Personal Information Overseas</h3>
      <p>
        Some of our third-party service providers are based outside of Australia, including in the United States. When we transfer your personal information overseas, we take reasonable steps to ensure that:
      </p>
      <ul>
        <li>You have given us your consent to transfer your personal information to the third party.</li>
        <li>We reasonably believe that the overseas recipient is subject to a law or binding scheme that is substantially similar to the Australian Privacy Principles (APPs).</li>
        <li>The disclosure is required or authorized by Australian law or a court/tribunal order.</li>
      </ul>


      <h3>9. How Do We Protect Your Personal Information?</h3>
      <p>
        AIssie employs a variety of security measures to ensure the safety of your personal information, including:
      </p>
      <ul>
        <li>Restricting access to personal information to authorized personnel who need it to provide services to you.</li>
        <li>Using encryption technologies to protect data in transit and at rest.</li>
        <li>Implementing firewalls, anti-virus software, and intrusion detection systems to safeguard our systems.</li>
        <li>Conducting regular security audits and assessments to identify and mitigate potential vulnerabilities.</li>
        <li>Providing ongoing training to employees on data protection and security best practices.</li>
        <li>Maintaining an incident response plan to address and manage data breaches effectively.</li>
      </ul>
      <p>
        We also require our third-party service providers to adhere to strict security standards to protect your personal information.
      </p>

      <h3>10. Online Activity (Cookies and Similar Technologies)</h3>
      <p>
        Our website and platform use cookies and similar technologies to enhance your user experience. Cookies are small files stored on your device that help us recognize you when you return to our website. We use different types of cookies for various purposes:
      </p>
      <ul>
        <li><strong>Essential Cookies:</strong> Necessary for the website to function properly.</li>
        <li><strong>Performance Cookies:</strong> Collect information about how you use our website to improve its performance.</li>
        <li><strong>Functionality Cookies:</strong> Remember your preferences and settings.</li>
        <li><strong>Targeting/Advertising Cookies:</strong> Deliver personalized advertisements based on your interests.</li>
      </ul>
      <p>
        You can manage your cookie preferences through your browser settings or by using our cookie consent management tool available on our website. Please note that disabling certain cookies may affect the functionality and user experience of our website.
      </p>

      <h3>11. Marketing Communications</h3>
      <p>
        With your consent, we may send you direct marketing communications and information about our services, opportunities, or events that may interest you. These communications may be sent via email, SMS, mail, or other methods in compliance with applicable marketing laws, including the Australian Spam Act 2003 (Cth).
      </p>
      <p>
        If you prefer a specific method of communication, please indicate your preference, and we will endeavor to use that method whenever practical. You can opt-out of receiving marketing communications at any time by contacting us using the details provided in the "How to Contact Us" section below.
      </p>
      <p>
        Additionally, we may use your personal information for advertising purposes, including online behavioral advertising and targeted advertising through third-party websites.
      </p>

      <h3>12. Retention of Personal Information</h3>
      <p>
        We retain your personal data on our servers and those of our cloud-based service providers in both the United States and Australia. The retention period is as follows:
      </p>
      <ul>
        <li>Personal data related to active user accounts is retained for the duration of your account's existence.</li>
        <li>After account closure, personal data is retained for an additional period necessary for operational analysis, historical records, and archival purposes, typically up to 5 years.</li>
        <li>Prospect data is retained until it is no longer of business value and is then securely deleted from our systems.</li>
      </ul>
      <p>
        Upon receiving a verified request from you or your authorized agent, we will delete any personal data under our control. For more details about data storage duration and location, or to exercise your rights regarding data erasure and portability, please contact us at <a href="mailto:contactus@aissie.com.au">contactus@aissie.com.au</a>.
      </p>

      <h3>13. Review of Third-Party Privacy Policies</h3>
      <p>
        Our services utilize third-party APIs, such as OpenAI, to generate responses. As a result, some user data may be processed by these third parties in accordance with their own privacy policies. We encourage you to review the privacy policies of these third parties to understand how your data may be processed:
      </p>
      <ul>
        <li><a href="https://openai.com/policies/api-data-usage-policies" target="_blank" rel="noopener noreferrer">OpenAI Privacy Policy</a></li>
        <li><a href="https://www.microsoft.com/en-us/legal/privacy" target="_blank" rel="noopener noreferrer">Microsoft Azure Privacy Policy</a></li>
        <li><a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Google Privacy Policy</a></li>
      </ul>
      <p>
        By using our services, you acknowledge and agree to the data processing practices of these third parties as outlined in their respective privacy policies.
      </p>

      <h3>14. How to Access and Correct Your Personal Information</h3>
      <p>
        We strive to keep your personal information accurate, complete, and up to date. If you wish to access or correct the personal information we hold about you, please:
      </p>
      <ul>
        <li>Contact us using the details provided in the "How to Contact Us" section below.</li>
        <li>Provide sufficient information to verify your identity.</li>
      </ul>
      <p>
        We will respond to your request within 30 days. In some cases, we may require additional time to respond, but we will notify you if this occurs.
      </p>

      <h3>15. Data Breach Notification</h3>
      <p>
        In the event of a data breach that may result in serious harm to you, we will notify you as soon as practicable. Our notification will include:
      </p>
      <ul>
        <li>A description of the breach.</li>
        <li>The types of personal information involved.</li>
        <li>The steps we are taking to address the breach.</li>
        <li>Recommendations for you to protect yourself from potential harm.</li>
      </ul>
      <p>
        We will also notify the relevant authorities in accordance with applicable laws.
      </p>

      <h3>16. Children's Privacy</h3>
      <p>
        Our platform is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If we become aware that we have inadvertently received personal information from a child under 18, we will delete such information promptly.
      </p>
      <p>
        If you believe that we might have any information from a child under 18, please contact us at <a href="mailto:contactus@aissie.com.au">contactus@aissie.com.au</a>.
      </p>

      <h3>17. Automated Decision-Making and Profiling</h3>
      <p>
        Our platform may use automated processes and algorithms to analyze data and generate chatbot responses. These processes are designed to enhance user experience and service functionality. We ensure that these automated decisions do not adversely affect your rights and freedoms. If you have concerns about any automated decision-making processes, please contact us using the details below.
      </p>

      <h3>18. Data Minimization and Purpose Limitation</h3>
      <p>
        We are committed to collecting only the personal information that is necessary for the specified purposes outlined in this Policy. We regularly review our data collection practices to ensure that we do not retain unnecessary data.
      </p>

      <h3>19. User Rights</h3>
      <p>
        In addition to the rights to access and correct your personal information, you may have the following rights depending on your jurisdiction:
      </p>
      <ul>
        <li><strong>Right to Data Portability:</strong> You can request to receive your personal data in a structured, commonly used, and machine-readable format.</li>
        <li><strong>Right to Restrict Processing:</strong> You can request that we limit the processing of your personal information.</li>
        <li><strong>Right to Object:</strong> You can object to the processing of your personal information for certain purposes, such as direct marketing.</li>
      </ul>
      <p>
        To exercise these rights, please contact us using the details provided below.
      </p>

      <h3>20. How to Contact Us</h3>
      <p>
        If you have any questions or concerns about our handling of your personal information or this Policy, you can contact us using the following methods:
      </p>
      <p><strong>Email:</strong> <a href="mailto:contactus@aissie.com.au">contactus@aissie.com.au</a></p>
      <p><strong>Contact Form:</strong> Available on our website - Contact Us tab</p>
      <p>
        We aim to respond to all inquiries within 5 business days. If you contact us by mail, please allow additional time for a response.
      </p>

      <h3>21. Data Protection Officer (DPO)</h3>
      <p>
        If required by applicable laws, our Data Protection Officer (DPO) is responsible for overseeing our data protection strategy and implementation. You can contact our DPO at:
      </p>
      <p><strong>Email:</strong> <a href="mailto:contactus@aissie.com.au">contactus@aissie.com.au</a></p>

      <h3>22. Changes to This Privacy Policy</h3>
      <p>
        We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes by posting the new Policy on our website and updating the effective date.
      </p>
      <p>
        Please review this Policy periodically to stay informed about how we are protecting your personal information.
      </p>

      <h3>23. Governing Law</h3>
      <p>
        This Privacy Policy is governed by and construed in accordance with the laws of Australia. Any disputes arising under or in connection with this Policy shall be subject to the exclusive jurisdiction of the courts of Australia.
      </p>

      <h3>24. Severability</h3>
      <p>
        If any provision of this Privacy Policy is found to be invalid or unenforceable by a court of competent jurisdiction, the remaining provisions will continue in full force and effect.
      </p>

      <h3>25. Entire Agreement</h3>
      <p>
        This Privacy Policy constitutes the entire agreement between you and AIssie regarding the subject matter herein and supersedes all prior and contemporaneous agreements, proposals, or representations, whether written or oral.
      </p>
    </div>
  );
};

export default Privacy;
