import React from 'react';
import './Footer.css';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

const Footer = () => (
  <footer className="footer">
      <div className="social-media">
      <a
          href="https://www.linkedin.com/company/aissie"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="AIssie on LinkedIn"
        >
          <FaLinkedinIn />
        </a>
        <a
          href="https://www.facebook.com/aissie.com.au/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="AIssie on Facebook"
        >
          <FaFacebookF />
        </a>
        <a
          href="https://www.instagram.com/aissie.com.au"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="AIssie on Instagram"
        >
          <FaInstagram />
        </a>

      </div>
    <p>&copy; {new Date().getFullYear()} AIssie. All rights reserved.</p>
    <nav>
      <a href="/terms-and-conditions">Terms and Conditions</a>
      <a href="/privacy-policy">Privacy Policy</a>
    
    </nav>
  </footer>
);

export default Footer;
