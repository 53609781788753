import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Import the CSS for the login page

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null); // Handle login errors
  const navigate = useNavigate(); // For navigation after login

  const handleLogin = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
  
      if (!response.ok) {
        setError(`HTTP error: ${response.status}`);
        return;
      }
  
      const result = await response.json();
  
      if (result.success) {
        if (result.session_id) {  // Check if session_id is in the response
          localStorage.setItem('session_id', result.session_id);
          navigate('/'); // Redirect to another page
        } else {
          setError("Session ID not received from the server");  // Error handling
        }
      } else {
        setError(result.message); // Handle login error message
      }
    } catch (err) {
      setError(`Error: ${err.message}`);
    }
  };
  
  

  return (
    <div className="login-container">
      <h1 className="login-heading">Login</h1>
      <div className="login-form">
        <div className="login-input-group">
          <input
            type="text"
            className="login-input"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
          />
        </div>
        <div className="login-input-group">
          <input
            type="password"
            className="login-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
        </div>
        <button className="login-button" onClick={handleLogin}>
          Login
        </button>
      </div>

      {error && (
        <div className="login-error"> {/* Display error message in case of failure */}
          {error}
        </div>
      )}
    </div>
  );
};

export default LoginPage;
