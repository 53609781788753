import React from 'react';
import './Privacy.css'; // Assuming a CSS file for styling

const Terms = () => {
  return (
    <div className='privacy'>
      <h2>Terms and Conditions</h2>
      <p><strong>Effective Date:</strong> 03/04/2024</p>
      <p><strong>Last Reviewed:</strong> 16/10/2024</p>

      <h3>1. Introduction</h3>
      <p>
        THESE TERMS AND CONDITIONS (the "Agreement") GOVERN YOUR ACCESS TO AND USE OF THE SERVICES PROVIDED BY AISSIE.COM.AU, INC. ("AIssie"). BY (A) ACQUIRING ACCESS TO THE SERVICE VIA AN ONLINE ORDER PROCESS THAT REFERENCES THIS AGREEMENT, (B) REGISTERING FOR A NO-COST OR PAID ACCESS PLAN FOR THE SERVICE VIA A PLATFORM THAT REFERENCES THIS AGREEMENT, OR (C) SELECTING A BOX INDICATING CONSENT, YOU AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT. THE INDIVIDUAL ACCEPTING THIS AGREEMENT DOES SO ON BEHALF OF AN ORGANISATION OR OTHER LEGAL ENTITY ("Customer"); SUCH INDIVIDUAL ASSERTS AND GUARANTEES THAT THEY POSSESS THE AUTHORITY TO BIND SUCH ENTITY AND ITS AFFILIATES TO THIS AGREEMENT. IF THE INDIVIDUAL ACCEPTING THIS AGREEMENT LACKS SUCH AUTHORITY, OR IF THE ENTITY DOES NOT CONSENT TO THESE TERMS AND CONDITIONS, SUCH INDIVIDUAL MUST NOT ACCEPT THIS AGREEMENT AND MAY NOT UTILISE THE SERVICES. CAPITALISED TERMS ARE DEFINED HEREIN. THE PARTIES AGREE AS STATED BELOW:
      </p>

      <h3>2. Definitions</h3>
      <ul>
        <li><strong>Service:</strong> The cloud-based artificial intelligence service provided by AIssie, including AI assistants for customer support, sales, and user interaction.</li>
        <li><strong>User Submission:</strong> Any configurations, customisations, uploads, or utilisation through the Service by the Customer, including content created by Users.</li>
        <li><strong>AIssie Materials:</strong> All materials provided by AIssie, including the Service, Software, AIssie Content, and Documentation.</li>
        <li><strong>Affiliate:</strong> Any entity directly or indirectly controlling, controlled by, or under common control with a party, where "control" means ownership of more than fifty percent (50%) of the voting shares or other equity interests.</li>
        <li><strong>Confidential Information:</strong> Business, technical, or financial information shared between the parties that is reasonably considered confidential.</li>
      </ul>

      <h3>3. The Service</h3>
      <ol>
        <li><strong>Service Description</strong>
          <p>
            AIssie offers a cloud-based artificial intelligence service providing AI assistants for customer support, sales, and user interaction (the "Service"). Any configurations, customisations, uploads, or utilisation through the Service by the Customer (including Users) are deemed a "User Submission." The Customer is exclusively responsible for all User Submissions contributed to the Service. Additional conditions regarding User Submissions, including ownership, are detailed in Section 8.2 below. The Service may encompass templates, scripts, documentation, and other materials assisting the Customer in using the Service ("AIssie Content"). Customers will not receive or have access to the underlying code or software of the Service (collectively, the "Software") nor receive a copy of the Software itself.
          </p>
        </li>
        <li><strong>Customer’s Subscription</strong>
          <p>
            Subject to the terms of this Agreement, the Customer may purchase a subscription to, and has the right to access and use, the Service as specified in one or more ordering screens agreed upon by the parties through AIssie's website or service portal that reference this Agreement and describe the business terms related to the Customer's subscription ("Order(s)"). All subscriptions are for the period described in the applicable Order ("Subscription Period"). Use of and access to the Service is permitted only for individuals authorised by the Customer and solely for the Customer's own internal business purposes, not for the benefit of any third party ("Users").
          </p>
        </li>
        <li><strong>AIssie’s Ownership</strong>
          <p>
            AIssie owns the Service, Software, AIssie Content, Documentation, and everything else provided by AIssie to the Customer (collectively, the "AIssie Materials"). AIssie retains all rights, title, and interest (including all intellectual property rights) in and to the AIssie Materials, all related and underlying technology, and any updates, enhancements, modifications, or fixes thereto, as well as all derivative works of or modifications to any of the foregoing. No implied licenses are granted under this Agreement, and any rights not expressly granted to the Customer are reserved by AIssie.
          </p>
        </li>
        <li><strong>Permissions</strong>
          <p>
            The Service includes customisable settings allowing Users to grant permissions to other Users to perform various tasks within the Service ("Permissions"). It is solely the Customer's responsibility to set and manage all Permissions, including determining which Users can set such Permissions. Accordingly, AIssie has no responsibility for managing Permissions and no liability for Permissions set by the Customer and its Users. The Customer may provide access to the Service to its Affiliates, in which case all rights granted and obligations incurred under this Agreement shall extend to such Affiliates. The Customer represents and warrants it is fully responsible for any breaches of this Agreement by its Affiliates and has the authority to negotiate this Agreement on behalf of its Affiliates. The Customer is also responsible for all payment obligations under this Agreement, regardless of whether the use of the Service is by the Customer or its Affiliates. Any claim by an Affiliate against AIssie must be brought by the Customer, not the Affiliate. An “Affiliate” of a party means any entity directly or indirectly controlling, controlled by, or under common control with that party, where “control” means the ownership of more than fifty percent (50%) of the voting shares or other equity interests.
          </p>
        </li>
      </ol>

      <h3>4. Restrictions</h3>
      <ol>
        <li><strong>Customer’s Responsibilities</strong>
          <p>
            The Customer is responsible for all activity on its account and those of its Users, except where such activity results from unauthorised access due to vulnerabilities in the Service itself. The Customer will ensure its Users are aware of and comply with the obligations and restrictions in this Agreement, bearing responsibility for any breaches by a User.
          </p>
        </li>
        <li><strong>Use Restrictions</strong>
          <p>
            The Customer agrees not to, and not to permit Users or third parties to, directly or indirectly:
          </p>
          <ul>
            <li>(a) modify, translate, copy, or create derivative works based on the Service;</li>
            <li>(b) reverse engineer, decompile, or attempt to discover the source code or underlying ideas of the Service, except as permitted by law;</li>
            <li>(c) sublicense, sell, rent, lease, distribute, or otherwise commercially exploit the Service;</li>
            <li>(d) remove proprietary notices from the Service;</li>
            <li>(e) use the Service in violation of laws or regulations;</li>
            <li>(f) attempt unauthorised access to or disrupt the Service;</li>
            <li>(g) use the Service to support products competitive to AIssie;</li>
            <li>(h) test the Service's vulnerability without authorisation.</li>
          </ul>
          <p>
            If the Customer’s use of the Service significantly harms AIssie or the Service’s security or integrity, AIssie may suspend access to the Service, taking reasonable steps to notify the Customer and resolve the issue promptly.
          </p>
        </li>
        <li><strong>API Access Restrictions</strong>
          <p>
            AIssie may provide access to APIs as part of the Service. AIssie reserves the right to set and enforce usage limits on the APIs, and the Customer agrees to comply with such limits. AIssie may also suspend or terminate API access at any time.
          </p>
        </li>
      </ol>

      <h3>5. Third-Party Services</h3>
      <p>
        The Service may interface with third-party products, services, or applications that are not owned or controlled by AIssie ("Third-Party Services"). Customers have the discretion to utilise these Third-Party Services in conjunction with our Service. Should the integration of the Service with any Third-Party Service require, customers will be responsible for providing their login information to AIssie solely for the purpose of enabling AIssie to deliver its Service. Customers affirm that they have the authority to provide such information without violating any terms and conditions governing their use of the Third-Party Services. AIssie does not endorse any Third-Party Services. Customers acknowledge that this Agreement does not cover the use of Third-Party Services, and they may need to enter into separate agreements with the providers of these services. AIssie expressly disclaims all representations and warranties concerning Third-Party Services. Customers must direct any warranty claims or other disputes directly to the providers of the Third-Party Services. The use of Third-Party Services is at the customer's own risk. AIssie shall not be liable for any issues arising from the use or inability to use Third-Party Services.
      </p>

      <h3>6. Financial Terms</h3>
      <ol>
        <li><strong>Fees</strong>
          <p>
            Customers are required to pay for access to and use of the Service as detailed in the applicable order ("Fees"). All Fees will be charged in the currency stated in the order or, if no currency is specified, in Australian Dollars (AUD). Payment obligations are non-cancellable and, except as explicitly stated in this Agreement, Fees are non-refundable. AIssie reserves the right to modify its Fees or introduce new fees at its discretion. Customers have the option not to renew their subscription if they disagree with any revised fees.
          </p>
        </li>
        <li><strong>Payment</strong>
          <p>
            AIssie, either directly or through its third-party payment processor ("Payment Processor"), will bill the customer for the Fees using the credit card or direct debit payment information provided by the customer. AIssie reserves the right to charge the customer's credit card or direct debit payment method for any services provided under the order, including recurring Fees. It is the customer's responsibility to ensure that AIssie has current and accurate credit card or direct debit payment information. Failure to provide accurate information may lead to a suspension of access to the Services. AIssie also reserves the right to offset any Fees owed by the customer. If the customer pays through a Payment Processor, such transactions will be subject to the Payment Processor's terms, conditions, and privacy policies, in addition to this Agreement. AIssie is not responsible for errors or omissions by the Payment Processor. AIssie reserves the right to correct any errors made by the Payment Processor, even if payment has already been requested or received. If the customer authorises, through accepting an order, recurring charges will be automatically applied to the customer's payment method without further authorisation until the customer terminates this Agreement or updates their payment method.
          </p>
        </li>
        <li><strong>Taxes</strong>
          <p>
            Fees do not include any taxes, levies, duties, or similar governmental assessments, including Goods and Services Tax (GST), imposed by any jurisdiction (collectively, "Taxes"). Customers are responsible for paying all Taxes associated with their purchases. If AIssie is obligated to pay or collect Taxes for which the customer is responsible, AIssie will invoice the customer for such Taxes unless the customer provides AIssie with a valid tax exemption certificate authorised by the appropriate taxing authority beforehand. For clarity, AIssie is solely responsible for taxes based on its income, property, and employees.
          </p>
        </li>
        <li><strong>Failure to Pay</strong>
          <p>
            If a customer fails to pay any Fees when due, AIssie may suspend access to the Service until overdue amounts are paid. AIssie is authorised to attempt charging the customer's payment method multiple times if an initial charge is unsuccessful. If a customer believes they have been incorrectly billed, they must contact AIssie within sixty (60) days from the first billing statement showing the error to request an adjustment or credit. Upon receiving a dispute notice, AIssie will review and provide the customer with a written decision, including evidence supporting this decision. If it is determined that the billed amounts are due, the customer must pay these amounts within ten (10) days of receiving AIssie's written decision.
          </p>
        </li>
      </ol>

      <h3>7. Term and Termination</h3>
      <ol>
        <li><strong>Agreement Term and Renewals</strong>
          <p>
            Subscriptions to access and use AIssie's service ("Service") commence on the start date specified on the applicable Order ("Subscription Start Date") and continue for the duration of the Subscription Period. Customers may opt not to renew their Subscription Period by notifying AIssie at billing@aissie.co (provided that AIssie confirms such cancellation in writing) or by modifying their subscription through the Customer’s account settings within the Service. This Agreement takes effect on the first day of the Subscription Period and remains effective for the duration of the Subscription Period stated on the Order, including any renewals of the Subscription Period and any period that the Customer is using the Service, even if such use is not under a paid Order ("Term"). If this Agreement is terminated by either party, it will automatically terminate all Orders. If a Customer cancels or chooses not to renew their paid subscription to the Service, the Customer's subscription will still be accessible but will automatically be downgraded to a version of the Service with reduced features and functionality that AIssie offers to unpaid subscribers ("Free Version"). Should this Agreement be terminated by either AIssie or the Customer, or should the Customer delete its workspace within the Service, access to the Free Version will be revoked.
          </p>
        </li>
        <li><strong>Termination</strong>
          <p>
            Either party may terminate this Agreement with written notice to the other party if the other party materially breaches this Agreement and such breach is not cured within thirty (30) days after receipt of such notice. AIssie may terminate a Customer's access to the Free Version at any time upon notice.
          </p>
        </li>
        <li><strong>Effect of Termination</strong>
          <p>
            If the Customer terminates this Agreement due to an uncured breach by AIssie, AIssie will refund any unused, prepaid Fees for the remainder of the then-current Subscription Period. If AIssie terminates this Agreement due to an uncured breach by the Customer, the Customer will pay any unpaid Fees covering the remainder of the then-current Subscription Period after the date of termination. No termination will relieve the Customer of the obligation to pay any Fees payable to AIssie for the period prior to the effective date of termination. Upon termination, all rights and licenses granted by AIssie will cease immediately, and the Customer will lose access to the Service. Within thirty (30) days of termination for cause, upon the Customer’s request, or if the Customer deletes its workspace within the Service, AIssie will delete the Customer’s User Information, including passwords, files, and submissions, unless an earlier deletion is requested in writing. For Customers using the Free Version, AIssie may retain User Submissions and User Information to facilitate continued use. AIssie may delete all User Submissions and User Information if an account remains inactive for more than one (1) year.
          </p>
        </li>
        <li><strong>Survival</strong>
          <p>
            Sections titled “AIssie's Ownership”, “Third-Party Services”, “Financial Terms”, “Term and Termination”, “Warranty Disclaimer”, “Limitation of Liability”, “Confidentiality”, “Data” and “General Terms” will survive any termination or expiration of this Agreement.
          </p>
        </li>
      </ol>

      <h3>8. Warranties and Disclaimers</h3>
      <ol>
        <li><strong>Warranties</strong>
          <p>
            Customers represent and warrant that all User Submissions submitted by Users comply with all applicable laws, rules, and regulations.
          </p>
        </li>
        <li><strong>Warranty Disclaimer</strong>
          <p>
            EXCEPT AS EXPRESSLY STATED HEREIN, THE SERVICES AND ALL RELATED COMPONENTS AND INFORMATION ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT ANY WARRANTIES OF ANY KIND, AND AIssie EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. CUSTOMERS ACKNOWLEDGE THAT AIssie DOES NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE. SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF CERTAIN WARRANTIES, SO THE FOREGOING DISCLAIMERS MAY NOT APPLY TO THE EXTENT PROHIBITED BY LAW.
          </p>
        </li>
      </ol>

      <h3>9. Limitation of Liability</h3>
      <p>
        NOTWITHSTANDING ANY PROVISION TO THE CONTRARY, AIssie WILL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL DAMAGES, OR DAMAGES BASED ON THE USE OR ACCESS, INTERRUPTION, DELAY, OR INABILITY TO USE THE SERVICE, LOST REVENUES OR PROFITS, LOSS OF BUSINESS OR GOODWILL, DATA CORRUPTION, OR SYSTEM FAILURES, REGARDLESS OF THE LEGAL THEORY. FURTHER, AIssie's TOTAL LIABILITY WILL NOT EXCEED THE TOTAL FEES PAID OR PAYABLE BY THE CUSTOMER FOR THE SERVICE DURING THE TWELVE (12) MONTHS PRIOR TO THE CLAIM. THESE LIMITATIONS APPLY REGARDLESS OF WHETHER AIssie HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.
      </p>

      <h3>10. Confidentiality</h3>
      <ol>
        <li><strong>Definition</strong>
          <p>
            Each party (the “Receiving Party”) recognises that the other party (the “Disclosing Party”) may share business, technical, or financial information pertaining to the Disclosing Party’s operations that, due to the nature of the information and the context of disclosure, is reasonably considered confidential (“Confidential Information”). For AIssie, Confidential Information includes non-public details about features, functionality, and performance of the Service. For Customers, Confidential Information comprises User Information and User Submissions. This Agreement, along with all related Orders, is considered Confidential Information of both parties. However, Confidential Information does not include information that: (a) becomes publicly available without breaching any duty to the Disclosing Party; (b) was known to the Receiving Party before disclosure by the Disclosing Party without breaching any duty; (c) is received from a third party without breaching any duty; or (d) was independently developed by the Receiving Party without using the Disclosing Party’s Confidential Information.
          </p>
        </li>
        <li><strong>Protection and Use of Confidential Information</strong>
          <p>
            The Receiving Party must:
          </p>
          <ul>
            <li>(a) protect the Disclosing Party’s Confidential Information with at least the same degree of care it uses for its own similar information, but no less than a reasonable level of care;</li>
            <li>(b) restrict access to Confidential Information to personnel, affiliates, subcontractors, agents, consultants, legal advisors, financial advisors, and contractors (“Representatives”) who need this information in relation to this Agreement and who are bound by confidentiality obligations similar to those in this Agreement;</li>
            <li>(c) not disclose any Confidential Information to third parties without prior written consent from the Disclosing Party, except as expressly stated herein;</li>
            <li>(d) use the Confidential Information solely to fulfil obligations under this Agreement.</li>
          </ul>
          <p>
            This does not prevent sharing of Agreement terms or the other party’s name with potential investors or buyers under standard confidentiality terms.
          </p>
        </li>
        <li><strong>Compelled Access or Disclosure</strong>
          <p>
            If required by law, the Receiving Party may access or disclose the Disclosing Party’s Confidential Information, provided that it notifies the Disclosing Party in advance (when legally permissible) and offers reasonable help, at the Disclosing Party's expense, if the Disclosing Party wants to contest the disclosure.
          </p>
        </li>
        <li><strong>Feedback</strong>
          <p>
            Customers may occasionally offer feedback on the Service (“Feedback”). AIssie may choose to incorporate this Feedback into its services. Customers grant AIssie a royalty-free, worldwide, perpetual, irrevocable, fully transferable, and sublicensable license to use, disclose, modify, create derivative works from, distribute, display, and exploit any Feedback as AIssie sees fit, without any obligation or restriction, except for not identifying the Customer as the source of Feedback.
          </p>
        </li>
      </ol>

      <h3>11. Data</h3>
      <ol>
        <li><strong>User Information</strong>
          <p>
            Customers and their Users must provide information like names, email addresses, usernames, IP addresses, browsers, and operating systems (“User Information”) to access the Service. Customers authorise AIssie and its subcontractors to store, process, and retrieve User Information as part of the Service usage. Customers guarantee they have the necessary rights to provide User Information to AIssie for processing as described in this Agreement. Customers are liable for their User Information and any unauthorised use of their credentials.
          </p>
        </li>
        <li><strong>User Submissions</strong>
          <p>
            Customers grant AIssie a non-exclusive, worldwide, royalty-free, transferable license to use, process, and display User Submissions solely to provide the Service. Beyond the rights granted here, Customers retain all rights to User Submissions, with no implied licenses under this Agreement.
          </p>
        </li>
        <li><strong>Service Data</strong>
          <p>
            AIssie collects data on Service performance and operation (“Service Data”) as Customers use the Service. Provided Service Data is aggregated and anonymised, without disclosing any personal information, AIssie can use this data freely. AIssie owns all rights to Service Data, but will not identify Customers or Users as its source.
          </p>
        </li>
        <li><strong>Data Protection</strong>
          <p>
            AIssie maintains reasonable security practices to protect Customer Data, including User Submissions and User Information. Nonetheless, Customers are responsible for securing their systems and data.
          </p>
        </li>
      </ol>

      <h3>12. General Terms</h3>
      <ol>
        <li><strong>Publicity</strong>
          <p>
            With prior written consent from the Customer, AIssie is allowed to identify the Customer and use and display the Customer’s name, logo, trademarks, or service marks on AIssie’s website and in AIssie’s marketing materials. This will help in demonstrating the clientele and user base of AIssie without compromising any confidential information or privacy rights of the Customer.
          </p>
        </li>
        <li><strong>Force Majeure</strong>
          <p>
            AIssie shall not be liable for any failure or delay in performing its obligations hereunder caused by events beyond its reasonable control, including but not limited to failures of third-party hosting or utility providers, strikes (excluding those involving AIssie’s employees), riots, fires, natural disasters, wars, terrorism, or government actions. These circumstances provide a shield for AIssie against unforeseen events that prevent it from fulfilling its service obligations.
          </p>
        </li>
        <li><strong>Changes</strong>
          <p>
            AIssie acknowledges that its service is an evolving, subscription-based product. To enhance customer experience, AIssie reserves the right to make modifications to the Service. However, AIssie commits to not materially reducing the core functionality provided to Customers. Furthermore, AIssie may modify the terms of this Agreement unilaterally, provided that Customers are notified at least thirty (30) days before such changes take effect, with changes posted prominently, for example, on the AIssie website terms page.
          </p>
        </li>
        <li><strong>Relationship of the Parties</strong>
          <p>
            This Agreement does not create a partnership, franchise, joint venture, agency, fiduciary, or employment relationship between AIssie and the Customer. Both parties are independent contractors, maintaining their respective operations and autonomy while cooperating under the terms laid out in this Agreement.
          </p>
        </li>
        <li><strong>No Third-Party Beneficiaries</strong>
          <p>
            This Agreement is strictly between AIssie and the Customer. It is not intended to benefit any third party, nor shall any third party have the right to enforce any of its terms, directly or indirectly. This clause clarifies the intended scope of the Agreement, limiting obligations and benefits to the parties involved.
          </p>
        </li>
        <li><strong>Email Communications</strong>
          <p>
            Notices under this Agreement will be communicated via email, although AIssie may choose to provide notices through the Service instead. Notices to AIssie must be directed to a designated AIssie email, while notices to Customers will be sent to the email addresses provided by them through the Service. Notices are considered delivered the next business day after emailing or the same day if provided through the Service.
          </p>
        </li>
        <li><strong>Amendment and Waivers</strong>
          <p>
            No modifications to this Agreement will be effective unless in writing and signed or acknowledged by authorised representatives of both parties. Neither party's delay or failure to exercise any right under this Agreement will be deemed a waiver of that right. Waivers must also be in writing and signed by the party granting the waiver.
          </p>
        </li>
        <li><strong>Severability</strong>
          <p>
            Should any provision of this Agreement be found unlawful or unenforceable by a court, it will be modified to the minimum extent necessary to make it lawful or enforceable, while the remaining provisions continue in full effect. This clause ensures the Agreement remains operational even if parts of it are modified or removed.
          </p>
        </li>
        <li><strong>Assignment</strong>
          <p>
            Neither party may assign or delegate their rights or obligations under this Agreement without the other party's prior written consent, except that AIssie may do so without consent in cases of mergers, acquisitions, corporate reorganizations, or sales of substantially all assets. Any unauthorised assignment will be void. This Agreement binds and benefits the parties, their successors, and permitted assigns.
          </p>
        </li>
        <li><strong>Governing Law and Venue</strong>
          <p>
            This Agreement will be governed by and construed in accordance with the laws of New South Wales, Australia, excluding its conflict of laws principles. Disputes arising under this Agreement will be resolved in the courts of New South Wales, to which both parties consent to jurisdiction and venue. There is a waiver of any right to a jury trial for disputes arising under this Agreement. The prevailing party in any enforcement action is entitled to recover its reasonable costs and attorney fees.
          </p>
        </li>
        <li><strong>Entire Agreement</strong>
          <p>
            This Agreement, including any referenced documents and Orders, constitutes the full agreement between AIssie and the Customer, superseding all prior discussions, agreements, and understandings of any nature. This ensures clarity and completeness in the mutual expectations and obligations of the parties involved.
          </p>
        </li>
      </ol>

    </div>
  );
};

export default Terms;
