import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TestSolution from './images/testsolution.webp';
import loaderGif from './images/loader.gif'; // Add the path to your loader gif
import './Connect.css';

const Connect = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // New loading state
  const [connectedBots, setConnectedBots] = useState({});
  const [facebookPages, setFacebookPages] = useState([]);
  const [instagramAccounts, setInstagramAccounts] = useState([]);

  useEffect(() => {
    /*const sessionId = localStorage.getItem('session_id');
    if (!sessionId) {
      navigate('/login');
    }*/
    window.scrollTo(0, 0);

    const script = document.createElement('script');
    script.src = 'https://connect.facebook.net/en_US/sdk.js';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: '3729084597409698',
          cookie: true,
          xfbml: true,
          version: 'v19.0',
        });
      };
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [navigate]);

  const handleFacebookLogin = () => {
    setIsLoading(true); // Start loading
    window.FB.login(
      (response) => {

        if (response.status === 'connected') {
          const accessToken = response.authResponse.accessToken;


          fetch(`${process.env.REACT_APP_API_URL}/api/updateFBToken`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ accessToken }),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.success) {
                fetchFacebookPages(accessToken).then((fetchedAccessToken) => {
                  fetchInstagramUsername(fetchedAccessToken);
                  setIsLoggedIn(true);
                  setIsLoading(false); // Stop loading
                });
              }
            })
            .catch((error) => {
              console.error('Error updating Facebook token:', error);
            });
        }
      },
      {
        scope: 'instagram_basic,instagram_manage_messages,pages_messaging,pages_manage_metadata,pages_show_list,business_management',
      }
    );
  };

  const fetchFacebookPages = (accessToken) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/accounts?access_token=${accessToken}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.data) {
          setFacebookPages(data.data);
          return data.data[0].access_token;
        }
      })
      .catch((error) => {
        console.error('Error fetching accounts:', error);
      });
  };

  // Function to fetch the Instagram business account ID and then retrieve the username
  const fetchInstagramUsername = (accessToken) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/getInstagramUsername?accessToken=${accessToken}`
      , {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.json())
      .then((data) => {
        if (!data.error) {
          // Successfully retrieved the username
          setInstagramAccounts([{ id: data.id, username: data.username }]);
        } else {
          console.warn(data.error);
          return null;
        }
      })
      .catch((error) => {
        console.error('Error fetching Instagram username:', error);
        return null;
      });
  };


  const handleConnectToBot = (page_id) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/connect`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ page_id }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setConnectedBots((prev) => ({
            ...prev,
            [page_id]: true,
          }));
        }
      })
      .catch((error) => {
        console.error('Error connecting to bot:', error);
      });
  };

  const handleDisconnectBot = (page_id) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/disconnect`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ page_id }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setConnectedBots((prev) => ({
            ...prev,
            [page_id]: false,
          }));
        }
      })
      .catch((error) => {
        console.error('Error disconnecting bot:', error);
      });
  };

  return (
    <div className="connect-container">
      <section className="connect-first-screen">
        <div className="connect-text-block">
          <h1>Connect our app to your page/Instagram!</h1>
          <div className="social-media-actions">
            {isLoggedIn ? (
              <p>Logged in with Facebook</p>
            ) : (
              <>
                <button
                  onClick={handleFacebookLogin}
                  aria-label="Facebook Login"
                  className="btn facebook-login-btn" // Improved styling
                >
                  Login with Facebook
                </button>
                {isLoading && (
                  <img src={loaderGif} alt="Loading" className="loading-gif" /> // Show loading gif if it's loading
                )}
              </>
            )}
          </div>

          {facebookPages.length > 0 && (
            <div className="managed-facebook-pages">
              <h2>Facebook Pages and Instagram Accounts You Manage:</h2>
              <ul>
                {facebookPages.map((page, idx) => {
                  const instagramAccount = instagramAccounts[idx];
                  const instagramAccountInfo = instagramAccount
                    ? ` / Instagram Account: ${instagramAccount.username}`
                    : '';
                  return (
                    <li key={page.id} className="facebook-page-info">
                      Facebook Page: {page.name}
                      {instagramAccountInfo}
                      {connectedBots[page.id] ? (
                        <button
                          onClick={() => handleDisconnectBot(page.id)}
                          className="btn disconnect-bot-btn"
                        >
                          Disconnect AIssie Bot
                        </button>
                      ) : (
                        <button
                          onClick={() => handleConnectToBot(page.id)}
                          className="btn connect-bot-btn"
                        >
                          Connect to AIssie Bot
                        </button>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>

        <div className="connect-image-placeholder">
          <img
            src={TestSolution}
            alt="AI Assistant"
            className="hero-image-connect"
          />
        </div>
      </section>
    </div>
  );
};

export default Connect;
