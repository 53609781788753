import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ChatWidget from './ChatWidget';
import './DemoInvestment.css'; // Renamed CSS file for clarity
import TestSolution from './images/testsolution.webp';

const InvestmentAIPage = () => {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    window.embeddedChatbotConfig = {
      chatbotId: 'f0f85833-52db-4970-b244-a6ca47c50cd7',
      account: 'Investment',
    };
    // Optional: Dynamically load chat widget script if necessary
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  // Generate structured data for the AI Assistant page
  const generateAIPageSchema = () => {
    const aiPageSchema = {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      name: 'Investment AI Assistant',
      description:
        'Explore Investment AI Assistant, designed to provide information about australia finance and investment options.',
      url: 'https://www.aissie.com.au/investment-demo',
      image: 'https://www.aissie.com.au/images/testsolution.webp', // Update with the correct image URL
    };
    return JSON.stringify(aiPageSchema);
  };

  // Generate breadcrumb structured data
  const generateBreadcrumbSchema = () => {
    const breadcrumbSchema = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      'itemListElement': [
        {
          '@type': 'ListItem',
          'position': 1,
          'name': 'Home',
          'item': 'https://www.aissie.com.au/',
        },
        {
          '@type': 'ListItem',
          'position': 2,
          'name': 'Investment AI Assistant',
          'item': 'https://www.aissie.com.au/investment-demo',
        },
      ],
    };
    return JSON.stringify(breadcrumbSchema);
  };

  return (
    <div className="investment-ai-container">
      <Helmet>
        <title>Investment AI Assistant</title>
        <meta
          name="description"
          content="Explore Investment AI Assistant, designed to provide information about australia finance and investment options."
        />
        <link rel="canonical" href="https://www.aissie.com.au/investment-demo" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: generateAIPageSchema() }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: generateBreadcrumbSchema() }}
        />
      </Helmet>

      <header className="first-screen-investment-ai">
        <div className="ai-text-block">
          <h1>Investment AI Assistant</h1>
          <p className="hero_subhead">
            Welcome to the Investment AI Assistant Demo! <br/> This assistant provides general
            Information about Australia's finance and investment options: The data used in this virtual assistant was sourced from the official <a href="https://moneysmart.gov.au/" target="_blank">MoneySmart website</a> and other finance websites.
            <br/> <b> Note: This assistant may provide inaccurate answers and should not be considered a finance advisor. Please ensure you validate the information through official websites or consult a personal finance advisor.</b>
</p>
        </div>

        <div className="image-placeholder">
          <img
            src={TestSolution}
            alt="AI Assistant demonstrating FAQ functionality"
            className="hero-image-faq"
          />
        </div>
      </header>

      <footer>
        <div id="investment-chat"></div>
        <ChatWidget />
      </footer>
    </div>
  );
};

export default InvestmentAIPage;
